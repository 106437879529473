import DailyIframe from '@daily-co/daily-js';
import { useRoom } from '@daily-co/daily-react-hooks';
import Bowser from 'bowser';

export function isCloudRecordingType(recType) {
  return recType === 'cloud' || recType === 'cloud-beta' || recType === 'raw-tracks';
}

export const useCallConfig = () => {
  const room = useRoom();

  // console.log('room', room)

  if (!room || Object.keys(room).length === 0) return {};

  const { config, domainConfig, tokenConfig } = room;

  // const hasFullUIControls = tokenConfig?.is_owner;
  const hasFullUIControls = true

  // console.log('tokenConfig', tokenConfig)

  const recordingType =
    tokenConfig?.enable_recording ?? config?.enable_recording;

  // console.log('recordingType', recordingType)

  const browser =
    typeof window === 'undefined'
      ? null
      : Bowser.getParser(window.navigator.userAgent).parsedResult;

  // console.log('browser', browser)
  const supportsLocalRecording =
    browser?.platform?.type === 'desktop' && browser?.engine?.name === 'Blink';

  // console.log('supportsLocalRecording', supportsLocalRecording)
  // console.log('hasFullUIControls', hasFullUIControls)
  // console.log('isCloudRecordingType(recordingType))', isCloudRecordingType(recordingType))
  // console.log('recordingType', recordingType)

  return {
    roomInfo: room,
    enableChat: !!config?.enable_chat,
    enableNetworkUI:
      config?.enable_network_ui ?? !!domainConfig?.enable_network_ui,
    enablePeopleUI:
      config?.enable_people_ui ?? domainConfig?.enable_people_ui ?? true,
    enableRecording:
      ((recordingType === 'local' && supportsLocalRecording) ||
        isCloudRecordingType(recordingType))
        ? recordingType
        : null,
    enableScreenShare:
      hasFullUIControls &&
      (tokenConfig?.enable_screenshare ?? config?.enable_screenshare) &&
      DailyIframe.supportedBrowser().supportsScreenShare,
    enableVideoProcessingUI:
      DailyIframe.supportedBrowser().supportsVideoProcessing &&
      (config?.enable_video_processing_ui ??
        !!domainConfig?.enable_video_processing_ui),
    optimizeLargeCalls: config?.experimental_optimize_large_calls,
    poweredByDaily:
      domainConfig && 'hide_daily_branding' in domainConfig
        ? !domainConfig?.hide_daily_branding
        : false,
  };
};
